import React from 'react';
import { Step } from './Step';
import './stepslist.scss';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';
import { IconButton } from '../IconButton/IconButton';
import { Button } from '../Button/Button';
import { ReactComponent as Arrow } from '../../assets/left.svg';
import { useSelector } from 'react-redux';
import parse from 'html-react-parser';
import sanitizeHtml from 'sanitize-html';

export const StepsList = ({
  currentStep,
  setCurrentStep,
  onAssociate,
  combination,
}) => {
  const { status } = useSelector((state) => state.request.confirmAssociation);

  const { t } = useTranslation();
  const I18n = {
    get: t,
  };

  const navigate = useNavigate();
  const steps = [];
  for (let i = 0; i < combination[currentStep].length; i++) {
    steps.push(
      <Step
        className="step"
        key={i}
        number={combination[currentStep][i]}
        text={I18n.get(
          'association_stepslist_step' + combination[currentStep][i]
        )}
        link={
          'association_stepslist_step' +
            combination[currentStep][i] +
            '_link' !==
            I18n.get(
              'association_stepslist_step' +
                combination[currentStep][i] +
                '_link'
            ) &&
          I18n.get(
            'association_stepslist_step' + combination[currentStep][i] + '_link'
          )
        }
        linkText={I18n.get(
          'association_stepslist_step' +
            combination[currentStep][i] +
            '_link_text'
        )}
        action={
          combination[currentStep][i] === 3 ? (
            <Button
              id="associate"
              type="button"
              variation="primary"
              onClick={() => {
                onAssociate();
              }}
              isLoading={status === 'pending'}
              disabled={status === 'success'}
            >
              {I18n.get('association_start')}
            </Button>
          ) : undefined
        }
        helpertext={
          'association_stepslist_step' +
            combination[currentStep][i] +
            '_helpertext' !==
            I18n.get(
              'association_stepslist_step' +
                combination[currentStep][i] +
                '_helpertext'
            ) &&
          I18n.get(
            'association_stepslist_step' +
              combination[currentStep][i] +
              '_helpertext'
          )
        }
        /* alternativeText={
          associating &&
          I18n.get('association_stepslist_step' + j + '_alternativetext') !==
            'association_stepslist_step' + j + '_alternativetext'
            ? I18n.get('association_stepslist_step' + j + '_alternativetext')
            : undefined
        } */
      />
    );
    /* if (i === 1) {
      steps.push(
        <div className="step-separator" key="separator">
          <div className="separator" />
          <span className="step-separator__text">
            {I18n.get('general_inapp')}
          </span>
        </div>
      );
    } */
  }
  return (
    <>
      <header className="stepscard__header">
        <div className="top-space">
          <IconButton
            size="small"
            icon={<Arrow />}
            onClick={() => {
              if (status !== 'idle') {
                navigate(`/`);
              } else {
                setCurrentStep(currentStep);
              }
            }}
          />
        </div>
      </header>
      <main className="stepscard__content">
        <div>
          <h3 className="stepscard__content__warning">
            {I18n.get('association_stepslist_warning')}
          </h3>
          <h2 className="stepscard__content__title">
            {I18n.get('association_stepslist_title')}
          </h2>
          <p className="stepscard__content__description">
            {parse(sanitizeHtml(I18n.get('association_stepslist_description')))}
          </p>
        </div>
        <ul className="stepslist">{steps}</ul>
      </main>
      <footer className="stepscard__footer">
        {currentStep === combination.length - 1 ? (
          <Button
            id="finished"
            type="button"
            onClick={() => {
              navigate('/association-end');
            }}
            disabled={status === 'idle'}
          >
            {I18n.get('general_next')}
          </Button>
        ) : (
          <Button
            id="next"
            type="button"
            onClick={() => {
              setCurrentStep(currentStep + 2);
            }}
            isLoading={status === 'pending'}
            disabled={status === 'success'}
          >
            {I18n.get('general_next')}
          </Button>
        )}
      </footer>
    </>
  );
};
