import { RESET } from '../actions/app';
import { SET_REQUEST_STATUS, SET_REQUEST_CONTROLLER } from '../actions/request';

const status = {
  PENDING: 'pending',
  SUCCESS: 'success',
  ERROR: 'error',
  IDLE: 'idle',
};

const initialState = {
  association: { status: status.IDLE, controller: new AbortController() },
  confirmAssociation: {
    status: status.IDLE,
    controller: new AbortController(),
  },
  requestDownload: { status: status.IDLE, controller: new AbortController() },
  confirmDownload: { status: status.IDLE, controller: new AbortController() },
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_REQUEST_STATUS:
      return {
        ...state,
        [action.request]: {
          ...state[action.request],
          status: action.status,
          payload: action.payload,
        },
      };
    case SET_REQUEST_CONTROLLER:
      return {
        ...state,
        [action.request]: {
          ...state[action.request],
          controller: action.controller,
        },
      };
    case RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
