import React from 'react';
import LanguageChanger from '../LanguageChanger';
import './footer.scss';
import { useTranslation } from 'react-i18next';

export const Footer = () => {
  const { t } = useTranslation();
  const I18n = {
    get: t,
  };
  return (
    <footer className="footer">
      <span className="copyrigth">
        {I18n.get('general_copyright')}
        {` `}
        {process.env.REACT_APP_BUILD_ENV !== 'PROD' &&
          (process.env.REACT_APP_BUILD_ENV || 'localhost')}
      </span>
      <LanguageChanger />
    </footer>
  );
};
