export const checkMail = (email) => {
  const regex_email = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const isValid = regex_email.exec(email);
  return isValid;
};

export const checkSn = (sn) => {
  //const regex_sn = /^[23456789a-hj-z]{6}|xplore$/i;
  const regex_sn = /^[23456789a-z]{6}$/i;
  //if (sn.length > 6) return false;
  const isValid = regex_sn.exec(sn);
  return isValid;
};
