import {
  SET_EMAIL,
  SET_SN,
  RESET,
  SET_EXTENSION,
  SET_ASSOCIATION_ID,
  SET_DOWNLOAD_ID,
  SET_ERROR,
  SET_MESSAGE,
} from '../actions/app';

const initialState = {
  email: '',
  sn: '',
  sending: true,
  error: '',
  message: null,
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_EMAIL:
      return {
        ...state,
        email: action.email,
      };
    case SET_SN:
      return {
        ...state,
        sn: action.sn,
      };
    case SET_EXTENSION:
      return {
        ...state,
        extension: action.extension,
      };
    case SET_ASSOCIATION_ID:
      return {
        ...state,
        associationId: action.associationId,
      };
    case SET_DOWNLOAD_ID:
      return {
        ...state,
        downloadId: action.downloadId,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.message,
      };
    case SET_MESSAGE:
      return {
        ...state,
        message: action.message,
      };
    case RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
