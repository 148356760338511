import React from 'react';
import './warning.scss';
import Balancer from 'react-wrap-balancer';
import maintenance from '../../assets/maintenance.json';
import { useTranslation } from 'react-i18next';

export const Warning = () => {
  const { t } = useTranslation();
  const I18n = {
    get: t,
  };

  const maintenanceDate = new Date(maintenance.dateTimeUTC);

  if (
    !maintenance.dateTimeUTC ||
    maintenanceDate.getTime() + 3600000 < Date.now()
  )
    return null;

  return (
    <div className="warning">
      <Balancer>
        {I18n.get('maintenance_warning_text').replace(
          '{{time}}',
          maintenanceDate.toLocaleDateString() +
            ' ' +
            maintenanceDate.getHours().toString().padStart(2, '0') +
            ':' +
            maintenanceDate.getMinutes().toString().padStart(2, '0')
        )}
      </Balancer>
    </div>
  );
};
