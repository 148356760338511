import { useEffect } from 'react';

export default function useEnvirronementInTitle() {
  useEffect(() => {
    document.title =
      (process.env.REACT_APP_BUILD_ENV === 'PROD'
        ? ''
        : (process.env.REACT_APP_BUILD_ENV || 'localhost') + ' - ') +
      'UniData access';
  }, []);
}
