import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { setEmail, setSn, requestDownload } from '../../actions/app';
import { setRequestStatus } from '../../actions/request';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Input } from '../Input/Input';
import { Button } from '../Button/Button';

import './home.scss';
import { Presentation } from '../Presentation/Presentation';
import { checkMail, checkSn } from '../../modules/checkers';
import useBackgroundColor from '../../hooks/useBackgroundColor';
import { Dialog } from '../Dialog/Dialog';

export const Home = () => {
  const { t, i18n } = useTranslation();
  const I18n = {
    get: t,
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search] = useSearchParams();

  const { email, sn } = useSelector((state) => state.app);
  const status = useSelector((state) => state.request.requestDownload.status);

  const [emailInvalid, setEmailInvalid] = useState(false);
  const [snInvalid, setSnInvalid] = useState(false);
  const [displayCGU, setDisplayCGU] = useState(false);

  useBackgroundColor('#0f1418');

  useEffect(() => {
    if (search.get('email')) {
      const queryEmail = search.get('email');
      dispatch(setEmail(queryEmail));
      setEmailInvalid(!checkMail(queryEmail));
    }
    if (search.get('sn')) {
      const querySn = search.get('sn');
      dispatch(setSn(querySn));
      setSnInvalid(!checkSn(querySn));
    }
    dispatch(setRequestStatus('requestDownload', 'idle'));
  }, [dispatch, search]);

  useEffect(() => {
    if (status === 'success') navigate('/mail-sending');
  }, [dispatch, navigate, status]);

  return (
    <div className="layout common">
      <section className="card">
        <header className="card__header"></header>
        <main className="card__content">
          <h2
            className="card__content__title" /*  style={{ marginTop: '60px' }} */
          >
            {I18n.get('home_card_title')}
          </h2>
          <p className="card__content__description">
            {I18n.get('home_card_description')}
          </p>
          {
            <Input
              id={displayCGU ? 'other' : 'email'}
              label={I18n.get('general_email')}
              key={displayCGU ? 'other' : 'email'}
              type={displayCGU ? 'other' : 'email'}
              value={email}
              placeholder={I18n.get('general_email_placeholder')}
              description={I18n.get('home_card_email_helpertext')}
              onChange={(e) => {
                dispatch(setEmail(e.target.value));
                setEmailInvalid(false);
              }}
              //success={checkMail(email)}
              error={emailInvalid}
              onBlur={() => {
                setEmailInvalid(!checkMail(email));
              }}
            />
          }
          <Input
            id="password"
            label={I18n.get('general_sn')}
            value={sn}
            type="text"
            onChange={(e) => {
              dispatch(setSn(e.target.value.toLowerCase()));
              setSnInvalid(false);
            }}
            placeholder={I18n.get('general_sn_placeholder')}
            description={I18n.get('home_card_sn_helpertext')}
            error={snInvalid && I18n.get('home_card_sn_error')}
            //success={checkSn(sn)}
            onBlur={() => {
              setSnInvalid(!checkSn(sn));
            }}
          />
          <span className="card__content__findsnlink">
            <a
              href={I18n.get('general_find_sn_link')}
              target="_blank"
              rel="noreferrer"
            >
              {I18n.get('general_find_sn')}
            </a>
          </span>
        </main>
        <footer className="card__footer">
          <p className="cgu-disclaimer">
            {I18n.get('home_card_CGU_acceptation_text')}&nbsp;
            <span
              className="cgu-disclaimer__link"
              tabIndex="0"
              onClick={() => {
                setDisplayCGU(true);
              }}
              onKeyDown={(e) => {
                if (e.code === 'Enter' || e.code === 'Space') {
                  e.preventDefault();
                  setDisplayCGU(true);
                }
              }}
            >
              {I18n.get('home_card_CGU_link_text')}
            </span>
          </p>
          <Button
            id="download"
            type="button"
            variation="primary"
            disabled={!(checkMail(email) && checkSn(sn))}
            onClick={async () => {
              await dispatch(requestDownload(email, sn, i18n.language));
            }}
            isLoading={status === 'pending'}
          >
            {I18n.get('general_validate')}
          </Button>
          {/*   <Button
            id="register"
            type="button"
            variation="default"
            disabled={!(checkMail(email) && checkSn(sn))}
            onClick={debounce(() => {
              dispatch(requestAssociation(email, sn, i18n.language));
            }, 500)}
            isLoading={registerStatus === 'pending'}
          >
            {I18n.get('general_register')}
          </Button> */}
        </footer>
      </section>
      <Presentation />
      <Dialog
        display={displayCGU}
        onClose={() => setDisplayCGU(false)}
      ></Dialog>
    </div>
  );
};
