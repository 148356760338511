import React from 'react';
import classNames from 'classnames';
import './chip.scss';

export const Chip = ({ children, selected, onSelect }) => {
  return (
    <span
      onClick={onSelect}
      className={classNames('chip', { 'chip--selected': selected })}
      tabIndex="0"
      onKeyDown={(e) => {
        if (e.code === 'Enter' || e.code === 'Space') {
          e.preventDefault();
          onSelect();
        }
      }}
    >
      {children}
    </span>
  );
};
