import React from 'react';
import { Link } from 'react-router-dom';

import './header.scss';
import { ReactComponent as Logo } from '../../assets/Logo.svg';
import { ReactComponent as Logotype } from '../../assets/Logotype.svg';

export const Header = () => {
  return (
    <header className="header">
      <div className="logo">
        <Link to="/" aria-label="home">
          <Logo />
          <Logotype />
        </Link>
        <span className="separator">{''}</span>
        <span className="product-name"> UniData access</span>
      </div>
    </header>
  );
};
