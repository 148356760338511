import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { IconButton } from '../IconButton/IconButton';
import { ReactComponent as Arrow } from '../../assets/left.svg';
import parse from 'html-react-parser';
import sanitizeHtml from 'sanitize-html';
import { useSelector } from 'react-redux';

import '../DataProcessing/dataprocessing.scss';

export const DefaultPage = ({ title, description, link }) => {
  const { email } = useSelector((state) => state.app);
  const { t } = useTranslation();
  const I18n = {
    get: t,
  };

  const navigate = useNavigate();

  return (
    <div className="layout common">
      <div className="download-confirm">
        <IconButton
          size="small"
          icon={<Arrow />}
          onClick={() => navigate(`/`)}
        />

        <h2 className="download-confirm__title">
          {parse(sanitizeHtml(I18n.get(title)))}
        </h2>
        <p className="download-confirm__description">
          {parse(
            sanitizeHtml(I18n.get(description)).replace('{{email}}', email)
          )}
          <br />
          {link && <a href={I18n.get(link)}>{I18n.get(link + '_text')}</a>}
        </p>
      </div>
    </div>
  );
};
