import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Button } from '../Button/Button';
import { IconButton } from '../IconButton/IconButton';
import { ReactComponent as Arrow } from '../../assets/left.svg';
import { Chip } from '../Chip/Chip';
import { Presentation } from '../Presentation/Presentation';
import './downloaddetails.scss';
import { setExtension } from '../../actions/app';
import { setDownloadId, confirmDownload, setMessage } from '../../actions/app';

import { setRequestStatus } from '../../actions/request';
import { debounce } from '../../modules/utils';
import useBackgroundColor from '../../hooks/useBackgroundColor';

const extensions = [
  { label: '.fits', value: 'fits' },
  { label: '.tiff', value: 'tiff' },
  { label: '.png', value: 'png' },
];

export const DownloadDetails = () => {
  const { t, i18n } = useTranslation();
  const I18n = {
    get: t,
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search] = useSearchParams();

  const { extension: selectedExtension, downloadId } = useSelector(
    (state) => state.app
  );
  const status = useSelector(
    (state) => state.request.confirmDownload?.status || 'idle'
  );

  useBackgroundColor('#0f1418');
  const queryId = search.get('id');
  //const querySn = search.get('sn');

  useEffect(() => {
    if (queryId) {
      try {
        dispatch(setDownloadId(queryId));
      } catch (error) {
        dispatch(setMessage({ text: 'The link is not valid', type: 'error' }));
        navigate(`/`);
      }
    } else {
      dispatch(setMessage({ text: 'The link is not valid', type: 'error' }));
      navigate(`/`);
    }
  }, [dispatch, search, navigate, i18n.language, queryId]);

  useEffect(() => {
    if (status === 'success') navigate('/download-dataprocessing');
  }, [status, dispatch, navigate]);

  useEffect(() => {
    return () => {
      dispatch(setRequestStatus('confirmDownload', 'idle'));
    };
  }, [dispatch]);

  return (
    <div className="layout common">
      <div className="card">
        <div className="card__content">
          <IconButton
            size="small"
            icon={<Arrow />}
            onClick={() => navigate(`/${i18n.language.substring(0, 2)}/`)}
          />
          <h2 className="card__content__title">
            {I18n.get('download_card_extension_title')}
          </h2>
          <p className="card__content__description">
            {I18n.get('download_card_extension_description')}
          </p>
          <div className="extension-list">
            {extensions.map((extension) => {
              return (
                <Chip
                  onSelect={() => {
                    if (extension.value !== selectedExtension)
                      dispatch(setExtension(extension.value));
                  }}
                  selected={extension.value === selectedExtension}
                  key={extension.label}
                >
                  {extension.label}
                </Chip>
              );
            })}
          </div>
        </div>
        <span className="card__footer">
          <Button
            id="signup"
            type="button"
            variation="primary"
            disabled={!selectedExtension || status === 'success'}
            onClick={debounce(async () => {
              dispatch(
                confirmDownload(downloadId, selectedExtension, i18n.language)
              );
            }, 500)}
            isLoading={status === 'pending'}
          >
            {I18n.get('general_send')}
          </Button>
        </span>
      </div>
      <Presentation />
    </div>
  );
};
