import { useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as Show } from '../../assets/show.svg';
import { ReactComponent as Hide } from '../../assets/hide.svg';
import './input.scss';

const DEFAULT_TYPE = 'text';
const PASSWORD_TYPE = 'password';

export const Input = (props) => {
  const {
    id,
    type,
    label = 'label',
    onAction,
    //actionIcon,
    placeholder,
    value,
    onChange,
    description,
    error,
    success = false,
    tabIndex = 0,
    required = false,
    disabled = false,
    onBlur,
    onFocus,
    maxLength,
    onPaste,
  } = props;
  const [revealPassword, setRevealPassword] = useState(false);
  const [showPlaceholder, setShowPlaceholder] = useState(false);
  const [moveLabel, setMoveLabel] = useState(false);
  const [focused, setFocused] = useState(false);

  return (
    <div className="input-wrapper">
      <div
        className={classNames('input', {
          'input--filled': value,
          'input--error': error,
          'input--success': success,
          'input--disabled': disabled,
          'input--focused': focused,
        })}
      >
        <label
          className={classNames('input__label', {
            'input__label--active': moveLabel || value,
          })}
          htmlFor={id}
        >
          {label}
        </label>
        <input
          id={id}
          type={revealPassword ? DEFAULT_TYPE : type}
          placeholder={showPlaceholder ? placeholder : ''}
          onFocus={() => {
            setShowPlaceholder(true);
            setMoveLabel(true);
            setFocused(true);
            if (onFocus) onFocus();
          }}
          onBlur={() => {
            setShowPlaceholder(false);
            setMoveLabel(false);
            setFocused(false);
            if (onBlur) onBlur();
          }}
          onChange={onChange}
          value={value}
          tabIndex={tabIndex}
          required={required}
          disabled={disabled}
          maxLength={maxLength}
          onPaste={onPaste}
          aria-label={label}
        />
        {(type === PASSWORD_TYPE || onAction) && (
          <button
            type="button"
            className="input__action"
            onClick={() =>
              type === PASSWORD_TYPE
                ? setRevealPassword(!revealPassword)
                : onAction()
            }
            tabIndex={tabIndex}
            disabled={disabled}
          >
            {revealPassword ? (
              <Hide aria-label="hide password" />
            ) : (
              <Show aria-label="show password" />
            )}
          </button>
        )}
      </div>
      {description && (
        <p
          className={classNames('input__description', {
            'input__description--error': error,
          })}
        >
          {description}
        </p>
      )}
    </div>
  );
};
