import { api } from '../modules/utils';
import { setRequestController, setRequestStatus } from './request';

export const SET_EMAIL = 'SET_EMAIL';
export const SET_SN = 'SET_SN';
export const SET_EXTENSION = 'SET_EXTENSION';
export const SET_ASSOCIATION_ID = 'SET_ASSOCIATION_ID';
export const SET_DOWNLOAD_ID = 'SET_DOWNLOAD_ID';
export const SET_ERROR = 'SET_ERROR';
export const SET_MESSAGE = 'SET_MESSAGE';
export const RESET = 'RESET';

export const setEmail = (email) => ({
  type: SET_EMAIL,
  email,
});

export const setSn = (sn) => ({
  type: SET_SN,
  sn,
});

export const setExtension = (extension) => ({
  type: SET_EXTENSION,
  extension,
});

export const setAssociationId = (associationId) => ({
  type: SET_ASSOCIATION_ID,
  associationId,
});

export const setDownloadId = (downloadId) => ({
  type: SET_DOWNLOAD_ID,
  downloadId,
});

export const setError = (message) => ({
  type: SET_ERROR,
  message,
});

export const setMessage = (message) => ({
  type: SET_MESSAGE,
  message,
});

export const reset = () => ({
  type: RESET,
});

/* export const requestAssociation =
  (email, sn, lang = 'en') =>
  async (dispatch, getState) => {
    dispatch(setRequestStatus('association', 'pending'));
    try {
      const controller = getState().request.confirmAssociation?.controller;
      if (controller) controller.abort();
      const newController = new AbortController();
      dispatch(setRequestController('association', newController));
      const url = new URL(api.baseUrl + '/exportassociate/create');
      const params = { email, sn, lang };
      url.search = new URLSearchParams(params).toString();
      const response = await api.get(url, {}, controller?.signal);
      dispatch(
        setMessage({
          text: 'association_banner_confirm_email',
          type: 'success',
        })
      );
      dispatch(setRequestStatus('association', 'success'));
    } catch (error) {
      dispatch(setRequestStatus('association', 'error', error));
      dispatch(setMessage({ text: error.message, type: 'error' }));
    }
  }; */

export const confirmAssociation = (id, lang) => async (dispatch, getState) => {
  dispatch(setRequestStatus('confirmAssociation', 'pending'));
  try {
    const controller = getState().request.confirmAssociation?.controller;
    if (controller) controller.abort();
    const newController = new AbortController();
    dispatch(setRequestController('confirmAssociation', newController));
    const url = new URL(api.baseUrl + '/exportassociate/confirm');
    const params = { id, lang };
    url.search = new URLSearchParams(params).toString();
    await api.get(url, {}, newController.signal);

    dispatch(setRequestStatus('confirmAssociation', 'success'));
    dispatch(setMessage({ text: 'association_banner_text', type: 'success' }));
  } catch (error) {
    dispatch(setRequestStatus('confirmAssociation', 'error', error));
    dispatch(setMessage({ text: error.message, type: 'error' }));
  }
};

export const requestDownload =
  (email, sn, lang) => async (dispatch, getState) => {
    dispatch(setRequestStatus('requestDownload', 'pending'));
    try {
      const controller = getState().request.requestDownload?.controller;
      if (controller) controller.abort();
      const newController = new AbortController();
      dispatch(setRequestController('requestDownload', newController));
      const url = new URL(api.baseUrl + '/exportrequest/create');
      const params = { email, sn, lang };
      url.search = new URLSearchParams(params).toString();
      await api.get(url, {}, newController.signal);

      dispatch(setRequestStatus('requestDownload', 'success'));
      /*  dispatch(
        setMessage({
          text: 'download_banner_confirm_email',
          type: 'success',
        })
      ); */
    } catch (error) {
      dispatch(setRequestStatus('requestDownload', 'error', error));
      dispatch(setMessage({ text: error.message, type: 'error' }));
    }
  };

export const confirmDownload =
  (id, extension, lang) => async (dispatch, getState) => {
    dispatch(setRequestStatus('confirmDownload', 'pending'));
    try {
      const controller = getState().request.confirmDownload?.controller;
      if (controller) controller.abort();
      const newController = new AbortController();

      dispatch(setRequestController('confirmDownload', newController));
      const url = new URL(api.baseUrl + '/exportrequest/confirm');
      const params = { id, ext: extension, lang };
      url.search = new URLSearchParams(params).toString();
      await api.get(url, {}, controller?.signal);

      dispatch(setRequestStatus('confirmDownload', 'success'));
    } catch (error) {
      console.log(error);
      dispatch(setRequestStatus('confirmDownload', 'error', error));
      dispatch(setMessage({ text: error.message, type: 'error' }));
    }
  };
