import React from 'react';
import parse from 'html-react-parser';
import sanitizeHtml from 'sanitize-html';
import { useTranslation } from 'react-i18next';
import { Button } from '../Button/Button';

import './dialog.scss';

export const Dialog = ({ display, onClose }) => {
  const { t } = useTranslation();
  const I18n = {
    get: t,
  };

  if (!display) return null;

  return (
    <div className="background" onClick={onClose}>
      <article className="dialog">
        <header className="dialog__title">{I18n.get('CGU_title')}</header>
        <main className="dialog__content">
          {parse(sanitizeHtml(I18n.get('CGU')))}
        </main>
        <footer className="dialog__footer">
          <Button variation="primary" onClick={onClose}>
            {I18n.get('general_close')}
          </Button>
        </footer>
      </article>
    </div>
  );
};
