export const getCountdownString = (duration) => {
  const minutes = Math.floor(duration / 1000 / 60);
  const seconds = Math.floor((duration - minutes * 60 * 1000) / 1000);

  return `${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`;
};

export const getValidLanguageFromPathname = (pathname, dict) => {
  const [, languageCode] = pathname.split('/');
  if (dict.find((lang) => lang.code === languageCode)) return languageCode;
  return '';
};

export const getLanguageFromPathname = (pathname) => {
  const [, languageCode] = pathname.split('/');
  if (languageCode.length === 2) return languageCode;
  return '';
};

export const api = {
  baseUrl: `https://${
    process.env.REACT_APP_BUILD_API || 'wt8ibmpel0'
  }.execute-api.us-east-1.amazonaws.com/${
    process.env.REACT_APP_BUILD_ENV?.toLowerCase() || 'dev'
  }`,
  get: async (url, options, signal) => {
    let data = undefined;
    let response = undefined;
    try {
      response = await fetch(url, { method: 'GET', ...options }, signal);
      if (response.ok) {
        data = await response.json();
        return data;
      } else {
        data = await response.json();
        if (data?.type || data?.code) {
          throw new Error(data.type || data.code);
        }
        throw new Error('general_error');
      }
    } catch (error) {
      if (data?.type || data?.code) {
        throw new Error(data.type || data.code);
      }
      throw new Error('general_error');
    }
  },

  post: async (url, options, signal) => {
    let data = undefined;
    let response = undefined;
    try {
      response = await fetch(url, { method: 'POST', ...options }, signal);
      if (response.ok) {
        data = await response.json();
        return data;
      } else {
        data = await response.json();
        if (data?.type || data?.code) {
          throw new Error(data.type || data.code);
        }
        throw new Error('general_error');
      }
    } catch (error) {
      console.log(data);
      if (data?.type || data?.code) {
        throw new Error(data.type || data.code);
      }
      throw new Error('general_error');
    }
  },
};

export const debounce = (wait, delay, now) => {
  let timerId;
  return (...args) => {
    clearTimeout(timerId);
    if (now) now();
    timerId = setTimeout(() => {
      wait(...args);
    }, delay);
  };
};
