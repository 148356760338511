import { useEffect } from 'react';
import useMediaQuery from './useMediaQuery';

export default function useBackgroundColor(color) {
  const isMobile = useMediaQuery('(max-width: 640px)');

  useEffect(() => {
    const htmlElement = document.querySelector('body');
    const oldColor = htmlElement.style.backgroundColor;

    if (isMobile) htmlElement.style.backgroundColor = color;
    return () => {
      htmlElement.style.backgroundColor = oldColor;
    };
  }, [color, isMobile]);
}
