import classNames from 'classnames';
import './button.scss';

export const Button = ({
  id,
  variation = 'default',
  onClick,
  size = 'medium',
  disabled,
  isLoading = false,
  isError = false,
  children,
  tabIndex = 0,
  className,
}) => {
  return (
    <button
      id={id}
      className={classNames(
        'button',
        { ['button--' + variation]: true },
        { 'button--small': size === 'small' },
        { 'button--loading': isLoading },
        { 'button--error': isError },
        className
      )}
      onClick={onClick}
      disabled={disabled || isLoading}
      tabIndex={tabIndex}
    >
      {children}
    </button>
  );
};
