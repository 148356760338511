export const SET_REQUEST_STATUS = 'SET_REQUEST_STATUS';
export const SET_REQUEST_CONTROLLER = 'SET_REQUEST_CONTROLLER';

export const setRequestStatus = (request, status, payload) => ({
  type: SET_REQUEST_STATUS,
  request,
  status,
  payload,
});

export const setRequestController = (request, controller) => ({
  type: SET_REQUEST_CONTROLLER,
  request,
  controller,
});
