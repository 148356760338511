import React, { useMemo, useEffect } from 'react';
import './App.scss';
import '@aws-amplify/ui-react/styles.css';
import i18n from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { languages } from './language';
import translationEN from './i18n/en.json';
import translationFR from './i18n/fr.json';
import translationDE from './i18n/de.json';
import translationJP from './i18n/jp.json';
import { useDispatch, useSelector } from 'react-redux';

import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { Header } from './components/Header/Header';
import { Footer } from './components/Footer/Footer';
import { Home } from './components/Home/Home';
import { AssociationProcess } from './components/AssociationProcess/AssociationProcess';
import { DownloadDetails } from './components/DownloadDetails/DownloadDetails';
import { DataProcessing } from './components/DataProcessing/DataProcessing';
import { getValidLanguageFromPathname } from './modules/utils';
import { Page404 } from './components/Page404/Page404';
import { Warning } from './components/Warning/Warning';
import { Banner } from './components/Banner/Banner';
import { setMessage } from './actions/app';
import useEnvirronementInTitle from './hooks/useEnvirronementInTitle';
import { DefaultPage } from './components/DefaultPage/DefaultPage';
import { Download } from './components/Download/Download';

const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
  de: {
    translation: translationDE,
  },
  jp: {
    translation: translationJP,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    fallbackLng: languages[1].code,
  });

i18n.changeLanguage(i18n.language.substring(0, 2));

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const message = useSelector((state) => state.app.message);

  const lang = useMemo(
    () => getValidLanguageFromPathname(location.pathname, languages),
    [location]
  );

  if (lang && lang !== i18n.language) {
    i18n.changeLanguage(lang);
  }

  const { t } = useTranslation();
  const I18n = {
    get: t,
  };

  useEffect(() => {
    if (!location.pathname.includes(i18n.language.substring(0, 2)))
      navigate(
        `/${i18n.language.substring(0, 2)}${location.pathname}${
          location.search
        }`,
        { replace: true }
      );
  }, [location, navigate]);

  useEnvirronementInTitle();

  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="*" element={<Warning></Warning>}></Route>
      </Routes>
      <Routes>
        <Route path={lang}>
          <Route index element={<Home />}></Route>
          <Route path="association" element={<AssociationProcess />}></Route>
          <Route path="download-details" element={<DownloadDetails />}></Route>
          <Route path="download" element={<Download />}></Route>
          <Route
            path="mail-sending"
            element={
              <DefaultPage
                title="mailsent_title"
                description="mailsent_description"
              />
            }
          ></Route>
          <Route
            path="association-end"
            element={
              <DefaultPage
                title="association_end_title"
                description="association_end_description"
                link="association_end_link"
              />
            }
          ></Route>
          <Route
            path="download-dataprocessing"
            element={<DataProcessing />}
          ></Route>
          <Route path="*" element={<Page404 />}></Route>
        </Route>
      </Routes>

      <Footer />
      {message && (
        <Banner
          open={message}
          delay={4}
          text={I18n.get(message?.text)}
          type={message?.type}
          onClose={() => {
            setTimeout(() => dispatch(setMessage()), 1000);
          }}
          //align="bottom"
        />
      )}
    </div>
  );
}

export default App;
