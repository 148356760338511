import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import thunk from 'redux-thunk';

import reducer from '../reducers';

const composeEnhancers = composeWithDevTools({});

const enhancer = (services) =>
  composeEnhancers(applyMiddleware(thunk.withExtraArgument(services)));

export const configureStore = () => createStore(reducer, enhancer());

export const configureInMemoryStore = (inMemoryAuth, inMemoryAPI) =>
  createStore(reducer, enhancer({ Auth: inMemoryAuth, API: inMemoryAPI }));
