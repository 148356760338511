import { Link, useParams } from 'react-router-dom';
import './page404.scss';
import React from 'react';

export const Page404 = () => {
  let { code } = useParams();
  if (!code) code = '404';
  return (
    <div className="page404">
      <p className="title">{code}</p>
      <p className="content">Are you lost ?</p>
      <Link to="/">Return to the homepage</Link>
    </div>
  );
};
