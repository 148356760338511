import React /* , { useEffect } */ from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate /* , useSearchParams */ } from 'react-router-dom';
/* import { useDispatch, useSelector } from 'react-redux';
import { Buffer } from 'buffer'; */

import { IconButton } from '../IconButton/IconButton';
import { ReactComponent as Arrow } from '../../assets/left.svg';
/* import { Presentation } from '../Presentation/Presentation';
import { setDownloadId, confirmDownload, setMessage } from '../../actions/app';
import { Loader } from '@aws-amplify/ui-react'; */
import sanitizeHtml from 'sanitize-html';
import parse from 'html-react-parser';
import './dataprocessing.scss';

export const DataProcessing = () => {
  const { t /* , i18n */ } = useTranslation();
  const I18n = {
    get: t,
  };

  const navigate = useNavigate();
  /*   const dispatch = useDispatch();
  const [search] = useSearchParams();
  const status = useSelector(
    (state) => state.request.confirmDownload?.status || 'idle'
  );

  useEffect(() => {
    if (status === 'error') navigate(`/`);
  }, [status, navigate]);

  console.log(status); */

  /* if (status === 'pending') return <Loader className="loader"></Loader>; */

  return (
    <div className="layout common">
      <div className="download-confirm">
        <IconButton
          size="small"
          icon={<Arrow />}
          onClick={() => navigate(`/`)}
        />

        <h2 className="download-confirm__title">
          {parse(sanitizeHtml(I18n.get('download_confirm_title')))}
        </h2>
        <p className="download-confirm__description">
          {parse(
            sanitizeHtml(I18n.get('download_card_processing_description'))
          )}
        </p>
      </div>
    </div>
  );
};
