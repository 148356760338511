import React from 'react';
import { useTranslation } from 'react-i18next';

import './presentation.scss';

export const Presentation = () => {
  const { t } = useTranslation();
  const I18n = {
    get: t,
  };
  return (
    <div className="presentation">
      <h2>{I18n.get('general_presentation_title_part1')}</h2>
      <h2>{I18n.get('general_presentation_title_part2')}</h2>
      <p>{I18n.get('general_presentation_description')}</p>
    </div>
  );
};
