import { SelectField } from '@aws-amplify/ui-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { languages } from '../../language';
import './style.scss';

const LanguageChanger = (/* { lang, setLang } */) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [lang, setLang] = useState(i18n.language.substring(0, 2));

  const changeLanguage = (e) => {
    const language = e.target.value;
    i18n.changeLanguage(language);
    setLang(language);
    navigate(
      '/' +
        language +
        location.pathname.replace('/' + lang, '') +
        location.search
    );
  };

  return (
    <div className="language-changer">
      <div>
        <SelectField
          value={lang}
          name="language"
          onChange={changeLanguage}
          label="langue"
          labelHidden
        >
          {languages.map((language, index) => (
            <option key={language.name} value={language.code}>
              {language.name}
            </option>
          ))}
        </SelectField>
      </div>
    </div>
  );
};

export default LanguageChanger;
