import classNames from 'classnames';
import { useCallback } from 'react';
import './iconbutton.scss';

export const IconButton = ({
  variation = 'default',
  onClick,
  size = 'medium',
  disabled,
  isLoading = false,
  isError = false,
  icon,
  className,
}) => {
  const handleToggle = useCallback(() => {
    if (onClick) onClick();
  }, [onClick]);

  return (
    <button
      className={classNames(
        'iconbutton',
        { ['iconbutton--' + variation]: true },
        { 'iconbutton--small': size === 'small' },
        { 'iconbutton--large': size === 'large' },
        { 'iconbutton--loading': isLoading },
        { 'iconbutton--error': isError },
        className
      )}
      onClick={() => handleToggle()}
      disabled={disabled}
      type="button"
    >
      {icon}
    </button>
  );
};
