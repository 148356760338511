import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import './banner.scss';

import { ReactComponent as Close } from '../../assets/close.svg';

export const Banner = ({
  text,
  type,
  delay = 2,
  open,
  onOpen,
  onClose,
  align,
}) => {
  const [inDelay, setInDelay] = useState(true);

  useEffect(() => {
    let timeout = null;
    if (delay) {
      timeout = setTimeout(() => {
        if (open) setInDelay(false);
        if (onClose) onClose();
      }, delay * 1000);
    } else {
    }
    if (onOpen) onOpen();

    return () => {
      clearTimeout(timeout);
    };
  }, [delay, onOpen, onClose, open]);

  if (!open) return null;

  return (
    <div
      className={classNames(
        'wrapper',
        { showed: open },
        { 'wrapper--bottom': align === 'bottom' }
      )}
    >
      <div
        className={classNames(
          'banner',
          { 'banner--error': type === 'error' },
          { disappear: !inDelay }
        )}
      >
        <div className="banner__content">{text}</div>
        {!delay && (
          <div
            className="banner__action"
            onClick={() => {
              setInDelay(false);
              if (onClose) onClose();
            }}
          >
            <Close />
          </div>
        )}
      </div>
    </div>
  );
};
