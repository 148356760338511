import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { Button } from '../Button/Button';
import { IconButton } from '../IconButton/IconButton';
import { ReactComponent as Arrow } from '../../assets/left.svg';
import { StepsList } from '../StepsList/StepsList';
import {
  setSn,
  setAssociationId,
  confirmAssociation,
  setMessage,
} from '../../actions/app';
import { checkSn } from '../../modules/checkers';
import './associationprocess.scss';
import { setRequestStatus } from '../../actions/request';
import sanitizeHtml from 'sanitize-html';
import parse from 'html-react-parser';
import useBackgroundColor from '../../hooks/useBackgroundColor';

export const AssociationProcess = () => {
  const { t, i18n } = useTranslation();
  const I18n = {
    get: t,
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search] = useSearchParams();
  const [currentStep, setCurrentStep] = useState(
    localStorage.getItem('countdownEnd') &&
      localStorage.getItem('countdownEnd') > Date.now()
      ? 2
      : 0
  );
  const { sn } = useSelector((state) => state.app);
  const { status } = useSelector((state) => state.request.confirmAssociation);

  //const data = Buffer.from(search.get('data'), 'base64').toString();
  const queryId = search.get('id');
  const querySn = search.get('sn');

  useBackgroundColor('#0f1418');

  useEffect(() => {
    if (queryId && querySn) {
      try {
        if (!checkSn(querySn) || !queryId) {
          dispatch(
            setMessage({ text: 'The link is not valid', type: 'error' })
          );
          navigate(`/`);
        }
        dispatch(setSn(querySn));
        dispatch(setAssociationId(queryId));
      } catch (error) {
        dispatch(setMessage({ text: 'The link is not valid', type: 'error' }));
        navigate(`/`);
      }
    } else {
      dispatch(setMessage({ text: 'The link is not valid', type: 'error' }));
      navigate(`/`);
    }
  }, [dispatch, navigate, search, queryId, querySn]);

  useEffect(() => {
    if (
      localStorage.getItem('countdownEnd') &&
      localStorage.getItem('countdownEnd') > Date.now()
    ) {
      dispatch(setRequestStatus('confirmAssociation', 'success'));
    }
  }, [dispatch]);

  return (
    <div className="layout">
      <div className="card howto">
        {!currentStep && (
          <>
            <header className="stepscard__header">
              <div className="top-space">
                <IconButton
                  size="small"
                  icon={<Arrow />}
                  onClick={() => navigate('/')}
                />
              </div>
            </header>
            <main className="stepscard__content">
              <div>
                <h3 className="stepscard__content__warning">
                  {I18n.get('association_stepslist_warning')}
                </h3>
                <h2 className="stepscard__content__title">
                  {I18n.get('association_stepslist_title')}
                </h2>
                <p className="stepscard__content__description">
                  {parse(
                    sanitizeHtml(I18n.get('association_stepslist_description'))
                  )}
                </p>
              </div>
              <div className="check-sn">
                <p className="step__main__content">
                  <span className="step__main__content__title">
                    {I18n.get('association_step_check_sn_title')}
                  </span>
                  <span className="step__main__content__helpertext">
                    {I18n.get('association_step_check_sn_description')}
                  </span>
                  <span className="sn">{sn}</span>
                </p>
              </div>
            </main>
            <span className="card__footer">
              <Button
                id="next"
                type="button"
                variation="default"
                onClick={() => {
                  setCurrentStep((currentStep) => currentStep + 1);
                }}
              >
                {I18n.get('general_confirm')}
              </Button>
            </span>
          </>
        )}
        {!!currentStep && (
          <StepsList
            combination={[
              [1, 2],
              [3, 4],
            ]}
            currentStep={currentStep - 1}
            setCurrentStep={setCurrentStep}
            associating={['success'].includes(status)}
            onAssociate={async () => {
              dispatch(confirmAssociation(queryId, i18n.language));
            }}
            onAssociateEnd={() => {
              dispatch(setRequestStatus('confirmAssociation', 'idle'));
            }}
          ></StepsList>
        )}
      </div>
    </div>
  );
};
