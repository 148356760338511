import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import dataImage from '../../assets/data.png';

export const Download = () => {
  const { t } = useTranslation();
  const I18n = {
    get: t,
  };
  const [params] = useSearchParams();

  useEffect(() => {
    const redirect = params.get('redirect');
    let timeout = null;
    if (
      redirect.startsWith(
        process.env.REACT_APP_BUILD_EXPORT_DOMAIN ||
          'https://s3-export-data-us-east-1.s3.amazonaws.com/'
      )
    ) {
      timeout = setTimeout(() => {
        window.location.replace(params.get('redirect'));
      }, 1000);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [params]);

  return (
    <>
      <p
        style={{
          padding: '2rem',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img
          style={{ maxWidth: '100%', width: '400px' }}
          src={dataImage}
          alt="download"
        />
        <span>
          {I18n.get('download_page_text')}&nbsp;
          <a href={params.get('redirect')}>
            {I18n.get('download_page_link_text')}
          </a>
        </span>
      </p>
    </>
  );
};
