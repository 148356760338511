import React from 'react';
import Balancer from 'react-wrap-balancer';

export const Step = ({ number, text, link, linkText, helpertext, action }) => {
  return (
    <div className="step">
      <div className="step__main">
        <span className="step__main__number">{number}</span>
        <p className="step__main__content">
          <span className="step__main__content__title">
            <Balancer ratio={0.65}>{text}</Balancer>
          </span>
          <span className="step__main__content__helpertext">{helpertext}</span>
          <span className="step__main__content__link">
            {link && (
              <a href={link} target="_blank" rel="noreferrer">
                {linkText}
              </a>
            )}
          </span>
        </p>
      </div>
      {action}
    </div>
  );
};
